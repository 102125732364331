<template>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card>
            <v-card-title>
              Data Iklan Pengguna 
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
            </v-card-title>
            <v-container>
              <v-data-table
                :headers="headers"
                :items="dataTable"
                :options.sync="options"
                :server-items-length="totalDataTables"
                :loading="loading"
                :search="search"
                :footer-props="{
                  'items-per-page-options' : [ 5, 10, 15, 100 ]
                }"
                class="elevation-1"
              >
              <template v-slot:top>
                  <v-dialog
                    v-model="dialogSettingsAds"
                    max-width="700px"
                  >
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Pilih Model Iklan yang Akan Ditampilkan</span>
                      </v-card-title>
  
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                                <v-select
                                v-model="id_master_settings_ads"
                                :items="masterSettingsAds"
                                label="Pilih Model Iklan"
                                multiple
                                chips
                                hint="Model iklan akan ditampilkan di Halaman Depan, Jika ingin menghapus klik saja save tanpa memilih Model Iklan"
                                persistent-hint
                                ></v-select>
                                <v-alert
                                v-if="Object.keys(formErrors).length > 0"
                                dense
                                outlined
                                type="error"
                                >
                               {{formErrors}}
                                </v-alert>
                                <v-text-field v-if="buttonEdit" v-show="false"
                                v-model="formData.key_id"
                                :error-messages="formErrors.key_id"
                                required
                                ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeSettingsAds"
                        >
                          Batal
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveSettingsAds"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="settingAds(item)"
                >
                  mdi-check-decagram-outline
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
              </v-data-table>
            </v-container>
  
            <!-- Start Snackbar -->
            <v-container>
              <div class="text-center">
                <v-snackbar
                  v-model="snackbar"
                  :multi-line="multiLine"
                >
                  {{message}} 
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="red"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Keluar
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
            </v-container>  
            <!-- End Snackbar -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
    // import { validationMixin } from 'vuelidate'
    // import { required, maxLength,minLength, email } from 'vuelidate/lib/validators'
    import axios from 'axios'
    
    export default {
      // mixins: [validationMixin],
  
      // validations: {
      //   title: { required, maxLength: maxLength(100) ,minLength: minLength(3)},
      //   email: { required, email },
      //   select: { required },
      //   checkbox: {
      //     checked (val) {
      //       return val
      //     },
      //   },
      // },
      data () {
        return {
          baseUrl : 'https://api.awakkerja.com/public/',
          baseUri : 'Api/UD/settingsAds',
          //start snackbar
          multiLine: true, 
          snackbar : false, 
          message : false, 
          //end snackbar
  
          //start datatable
          search  : '', 
          dataTable : [], 
          totalDataTables : 0, 
          loading: true, 
          options: {}, 
          headers: [ 
            {
              text: '#',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Title', value: 'title' },
            { text: 'Description', value: 'description' },
            { text: 'Location', value: 'location' },
            { text: 'Price', value: 'price' },
            { text: 'Categories', value: 'id_master_categories' },
            { text: 'Settings Ads',value: 'settings_ads'},
            { 
              text: 'Aksi', 
              value:'actions',
              sortable : false
            }
          ],
          dialogSettingsAds : false,
          //end datatable
  
          //start form
          // title: '', 
          // key_id : '',
          // image   : '',
          buttonEdit : false,
          // idMasterKategoriErrors : '',
          // imageErrors: '',
          formErrors : {},
          formData : {
            title : '',
            key_id : '',
          },
          memuat : false,
          statusImage : false,
          rejected_message : null,
          id_master_settings_ads : null,
          masterSettingsAds : [],
          // email: '',
          // select: null,
          // items: [
          //   'Item 1',
          //   'Item 2',
          //   'Item 3',
          //   'Item 4',
          // ],
          // checkbox: false,
        }
      },
      computed : {
        
      },
      watch: {
        //Start Datatable
        options: {
          handler () {
            this.setApiData()
          },
          deep: true,
        },
        search : {
          handler(){
            this.setApiData()
            this.options.itemsPerPage = 10
            this.options.page = 1
          },
          deep : true,
        },
        //End Datatable
      },
      mounted () {
        //Start Datatable
        this.setApiData()
        //End Datatable
        this.checkLogin()
      },
      methods: {
        checkLogin(){
          //check Login
          let vm = this
          setTimeout(function(){
            axios({
              method : 'get',
              url  : vm.baseUrl+'Api/UD/Auth/checkToken',
              headers : {
                'Authorization' : vm.$session.get('jwt')
              }
            }).then(function(response){
              if(response.data.status!==200){
                vm.$session.destroy()
                vm.$router.push('/')
              }
            })
          },1000)
          //end check login
        },
        //start form
        clear () {
  
          for(let k in this.formData){
            this.formData[k] = null
          }
        },
        batalEdit (){
          this.title = ''
          this.formData.key_id = ''
          this.buttonEdit = false
        },
        //end form
  
        //start table
        setApiData (){
          this.loading = true
          this.getData().then(response=>{
            this.dataTable = response.data.rows
            this.totalDataTables = response.data.totalRows
            this.loading = false
          })
        },
        getData (){
          return new Promise((resolve,reject)=>{
  
            let vm = this;
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            axios({
              method: 'get',
              url: vm.baseUrl+vm.baseUri,
              headers : {
                'Authorization' : vm.$session.get('jwt')
              },
              params: {
                sortBy       : sortBy,
                sortDesc     : sortDesc,
                page         : page,
                itemsPerPage : itemsPerPage,
                search       : (this.search!='')?this.search:false
              }
            })
            .then(function (response) {
              if(response.status===200){
                resolve(response)
              }else{
                reject('Terjadi Kesalahan, Hubungi programmer')
              }
            })
          })
        },
        closeSettingsAds (){
          this.dialogSettingsAds = false
          this.clear()
          this.masterSettingsAds = []
          this.formErrors = []
          this.id_master_settings_ads = null
        },
        saveSettingsAds (){
          let vm = this
          axios({
            method : 'post',
            url    : vm.baseUrl+vm.baseUri+'/saveSettingsAds',
            headers : {
            'Authorization' : vm.$session.get('jwt')
            },
            data: {
              key_id : vm.formData.key_id,
              id_master_settings_ads : vm.id_master_settings_ads
            }
          }).then(function(response){
              if(response.data.status){
                vm.id_master_settings_ads = ''
                vm.setApiData()
                vm.closeSettingsAds()
              }
              vm.snackbar = true
              vm.message = response.data.message
          }).catch(function(error){
              vm.formErrors = error.response.data.formErrors
              vm.snackbar = true
              vm.message = error.response.data.message
          })
        },
        settingAds (item){
          this.dialogSettingsAds = true
          this.formData.key_id = item.actions
            let vm = this
            axios({
            method : 'get',
            url    : vm.baseUrl+vm.baseUri+'/getMasterSettingsAds',
            headers : {
            'Authorization' : vm.$session.get('jwt')
            },
            }).then(function(response){
                if(response.status==200){
                    vm.masterSettingsAds = response.data
                }else{
                    vm.snackbar = true
                }
            })
          // alert('tes')
        },
        initialize (){
          this.search = false
        }
      },
    }
  </script>